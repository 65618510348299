import { joinURL, withBase, withoutTrailingSlash } from 'ufo'
import { computed } from 'vue'

import { useRoute, useT3Api, useT3Options, useT3i18n } from '#imports'

const useMetaData = () => {
  const { query, path } = useRoute()

  const { getCurrentLocaleData } = useT3i18n()
  const currentLocaleData = getCurrentLocaleData()

  const { pageData } = useT3Api()
  const metaData = computed(() => pageData.value?.meta)
  const i18nData = computed(() => pageData.value?.i18n ?? [])

  if (metaData.value === undefined) return { data: {} }

  const { options } = useT3Options()
  const baseUrl = options.baseUrl
  const apiBaseUrl = options.api.baseUrl

  const robots = computed(() => {
    const noIndexFollowParamArray = ['utm_', 'offer_id', 'hsa_', 'tx_news_pi1']
    const noIndexFollow = Object.keys(query).some((queryKey) =>
      noIndexFollowParamArray.some((key) => queryKey.includes(key))
    )

    const apiContent = Object.entries(metaData.value?.robots || {})
      .map(([key, value]) => key.replace(value ? '' : 'no', ''))
      .join(', ')
      .toLocaleLowerCase()

    return [
      {
        name: 'robots',
        content: noIndexFollow ? 'noindex, follow' : apiContent
      }
    ]
  })

  const twitter = computed(() => {
    const data = metaData.value!

    const twitterImage = data.twitterImage?.publicUrl || undefined

    return [
      {
        name: 'twitter:title',
        content: data.twitterTitle || data.title
      },
      {
        name: 'twitter:description',
        content: data.twitterDescription || data.description
      },
      {
        name: 'twitter:image',
        content: twitterImage
      },
      {
        name: 'twitter:card',
        content: data.twitterCard || 'summary'
      }
    ]
  })

  const openGraph = computed(() => {
    const data = metaData.value!

    const fileadminOgImage = () => {
      if (apiBaseUrl.includes('t3headless')) return undefined

      const fileadminPath = '/fileadmin/assets/media-social'
      const filename = `og-image-${currentLocaleData?.twoLetterIsoCode}.jpg`

      return withBase(joinURL(fileadminPath, filename), apiBaseUrl)
    }

    const ogImage = data.ogImage?.publicUrl || fileadminOgImage() || undefined

    const ogLocale: { current: string | undefined; alternate: Array<string> } =
      { current: undefined, alternate: [] }

    i18nData.value.forEach((lang) => {
      const locale = lang.locale.split('.')[0]

      if (lang.active) {
        ogLocale.current = locale
      } else if (lang.available === 1) {
        ogLocale.alternate.push(locale)
      }
    })

    return [
      {
        property: 'og:title',
        content: data.ogTitle || data.title
      },
      {
        property: 'og:description',
        content: data.ogDescription || data.description
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:image',
        content: ogImage
      },
      {
        property: 'og:url',
        content: withoutTrailingSlash(data.ogUrl?.href) || undefined
      },
      {
        property: 'og:locale',
        content: ogLocale.current
      },
      ...ogLocale.alternate.map((locale) => ({
        property: 'og:locale:alternate',
        content: locale
      }))
    ]
  })

  const meta = computed(() => {
    return [
      {
        name: 'description',
        content: metaData?.value?.description || undefined
      },
      {
        name: 'keywords',
        content: metaData?.value?.keywords || undefined
      },
      ...robots.value,
      ...twitter.value,
      ...openGraph.value
    ]
  })

  const link = computed(() => {
    const linkArr: { rel: string; hreflang?: string; href: string }[] = []

    const hreflang: {
      default: string
      array: Array<{ code: string; link: string }>
    } = {
      default: '',
      array: [{ code: options.i18n.default, link: '' }]
    }

    if (i18nData.value.length) {
      hreflang.array = []

      i18nData.value
        .filter((lang) => lang.available === 1)
        .map((lang) => ({
          code: lang.twoLetterIsoCode,
          link: lang.link
        }))
        .forEach((lang) => {
          if (!hreflang.default) {
            hreflang.default = lang.link
          }
          hreflang.array.push(lang)
        })
    }

    linkArr.push(
      {
        rel: 'alternate',
        hreflang: 'x-default',
        href: withoutTrailingSlash(baseUrl + hreflang.default)
      },
      ...hreflang.array.map((lang) => ({
        rel: 'alternate',
        hreflang: lang.code,
        href: withoutTrailingSlash(baseUrl + lang.link)
      }))
    )

    let canonical = metaData.value?.canonical.href || ''

    const containsQueryParameters = Object.keys(query).length
    if (containsQueryParameters) {
      canonical = baseUrl + path
    }

    const tagsView = path.includes('/tags')
    if (tagsView) {
      canonical = baseUrl + path?.replace(/\/tags\/.*/, '')
    }

    if (canonical) {
      linkArr.push({
        rel: 'canonical',
        href: withoutTrailingSlash(canonical)
      })
    }

    return [
      {
        rel: 'preconnect',
        href: withoutTrailingSlash(apiBaseUrl)
      },
      ...linkArr
    ]
  })

  const data = computed(() => {
    return {
      title: metaData.value?.title,
      htmlAttrs: {
        lang: currentLocaleData?.twoLetterIsoCode,
        dir: currentLocaleData?.direction
      },
      meta,
      link
    }
  })

  return { data }
}

export default useMetaData
